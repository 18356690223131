export const primary_green = '#3Baa34'
export const secondary_green = '#2E842A'
export const tertiary_green = '#256C22'
export const white = '#FFFFFF'
export const secondary_white = '#FAFAFA'
export const lightblue = '#65C5EF'
export const text_blue = '#2A3E84'
export const red = '#E40613'
export const black = '#1D1D1B'
export const secondary_black = '#404040'
export const tertiary_black = '#393939'
export const quarternary_black = '#000000'
export const fifth_black = '#272727'
export const dark_grey = '#373737'
export const button_text_black = '#2A2A2A'
export const button_text_black_hover = '#424242'
export const blue = '#1F486A'
export const link_blue = '#2A3E84'
export const lightgrey = '#F8F8F8'
export const tag_grey = '#FEFEFE'
export const tag_border_grey = '#DFDFDF'
export const grey = '#8C8C8C'
export const list_border_grey = '#D8D8D8'
export const list_bg_grey = '#FCFCFC'
export const grey_background = '#F4F6F8'
export const lightgrey_background = '#FBFBFB'
export const search_border_grey = '#C7C7C7'
export const searchbar_bg = '#F2F2F2'
export const event_border_grey = '#D1D1D1'
export const button_grey = '#F6F6F6'
export const second_button_grey = '#EDEAEA'

// subsite
export const primary_orange = '#EC6535'
export const primary_orange_dark = '#D45A2F'
export const primary_orange_light = '#ED7449'

export const subsite_background = '#E9F3F6'
export const subsite_dark_background = '#1F486A'

export const subsite_footer_text = '#A2C4CE'
export const subsite_hover_blue = '#355A78'

export const subsite_title_blue = '#2488A8'
