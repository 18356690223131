'use client'

// @INFO
//
// This wraps around the whole project, but needs clientside logic.
//

// NEXT.JS / REACT IMPORTS
import { useEffect } from 'react'

// SPORT050 Specific utils
import ScrollWrapper from '@/_layout/utils/scroll'
import MyApplicationFilterState from '@/core/filterContext'

// MUI CORE
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter'
import { ThemeProvider } from '@mui/material/styles'
import theme from '@/core/materialTheme'

// MOMENT.JS
import moment from 'moment'
import 'moment/locale/nl'
import 'moment-timezone'

// Styling
import '@/styles/globals.css'

// defaultFilters
const defaultFilters = {
  order: 'title',
  page: 1,
  data: [],
  filters: [],
  category: '',
  attributes: [],
  additionals: [],
}

// ClientWrapper serves clientside logic such as providers and theme related logic
const ClientWrapper = ({ children }) => {
  useEffect(() => {
    moment.locale('nl-NL')
  }, [])

  return (
    <AppRouterCacheProvider>
      <ThemeProvider theme={theme}>
        <ScrollWrapper>
          <MyApplicationFilterState defaultState={defaultFilters}>
            {children}
          </MyApplicationFilterState>
        </ScrollWrapper>
      </ThemeProvider>
    </AppRouterCacheProvider>
  )
}
export default ClientWrapper
