'use client'
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

// Import resources
import ArrowUpward from '@mui/icons-material/ArrowUpward'

// Functions
function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0)

  function handleScroll() {
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const windowScroll = document.documentElement.scrollTop

    const scrolled = (windowScroll / height) * 100

    setScrollPosition(scrolled)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return scrollPosition
}

// Styling elements
const ScrollUp = styled.div`
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  background: #2e842a;
  color: #fff;
  fill: #fff;
  border-radius: 5px;
  z-index: 99999;
  cursor: pointer;

  &:focus {
    outline-color: #000 !important;
  }

  &:hover {
    background: #236b20;
  }

  &.show {
    display: block;
  }

  svg {
    position: relative;
    top: 16px;
    left: 18px;
    transform: scale(1.6);
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
`

const ScrollWrapper = ({ children }) => {
  const scrollPos = useScrollPosition()
  const pageReference = useRef()
  const scrollToSection = (indexToScrollToo = 0) => {
    const ele = [...pageReference.current.children]
    if (ele) {
      ele.forEach((section, index) => {
        if (index === indexToScrollToo) {
          section.scrollIntoView({ behavior: 'smooth' })
        }
      })
    }
  }

  return (
    <>
      <div ref={pageReference}>{children}</div>
      <ScrollUp
        onClick={() => scrollToSection()}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            scrollToSection()
          }
        }}
        className={`${scrollPos > 6 ? 'show' : ''}`}
        tabIndex={0}
      >
        <ArrowUpward />
      </ScrollUp>
    </>
  )
}

export default ScrollWrapper
