import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import {
  black,
  button_text_black,
  button_text_black_hover,
  dark_grey,
  fifth_black,
  lightgrey,
  grey,
  primary_green,
  quarternary_black,
  red,
  secondary_black,
  secondary_green,
  tertiary_black,
  tertiary_green,
  white,
  grey_background,
  tag_border_grey,
  text_blue,
  button_grey,
  second_button_grey,
  lightgrey_background,
  subsite_title_blue,
} from './colors'
import { GLOBAL_SKEW } from './constants'

const globalTheme = createTheme({
  palette: {
    green: {
      light: '#3Baa34',
      main: primary_green,
      dark: '#3Baa34',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary_green: {
      main: secondary_green,
    },
    white: {
      main: white,
    },
    primaryCustom: {
      light: primary_green,
      main: secondary_green,
      dark: tertiary_green,
      contrastText: white,
    },
    dateColor: {
      light: red,
      main: red,
      dark: red,
      contrastText: white,
    },
    backgroundColor: {
      light: grey_background,
      main: grey_background,
      dark: grey_background,
      contrastText: grey_background,
    },
    footerText: {
      light: black,
      main: black,
      dark: secondary_black,
      contrastText: black,
    },
  },
})

const theme = createTheme({
  name: 'main',
  typography: {
    fontFamily: 'Dosis, Poppins',
    Title: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.5,
      display: 'block',
      paddingBottom: 20,
      fontFamily: 'dosis',
    },
    Subtitle: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.25,
      display: 'block',
      paddingBottom: 20,
      fontFamily: 'dosis',
    },
    ButtonText: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.25,
      display: 'inline-block',
      fontFamily: 'dosis',
      textTransform: 'uppercase',
    },
    SecondaryButtonText: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '23px',
      display: 'block',
      fontFamily: 'dosis',
      textTransform: 'uppercase',
    },
    BlankButtonText: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
      fontFamily: 'dosis',
      textTransform: 'uppercase',
      color: black,
    },
    PlusButtonText: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 2,
      fontFamily: 'Poppins',
      color: quarternary_black,
      marginTop: 7,
    },
    MoreButtonText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      display: 'block',
      textTransform: 'none',
      marginTop: 8,
    },
    IconButtonText: {
      fontFamily: 'Dosis',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      color: fifth_black,
    },
    MoreFiltersButtonText: {
      fontFamily: 'Dosis',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.25,
      display: 'block',
      textTransform: 'uppercase',
      color: secondary_green,
      svg: {
        verticalAlign: 'bottom',
      },
    },
    TabText: {
      fontFamily: 'Dosis',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    HeaderTitle: {
      fontWeight: 600,
      fontSize: 96,
      lineHeight: 1.26,
      display: 'block',
      paddingBottom: 20,
      fontFamily: 'dosis',
      color: black,
    },
    HeaderTitleWhite: {
      fontWeight: 600,
      fontSize: 96,
      lineHeight: 1.26,
      display: 'block',
      paddingBottom: 20,
      fontFamily: 'dosis',
      color: white,
    },
    HeaderSubTitleWhite: {
      fontWeight: 600,
      fontSize: 34,
      lineHeight: 1.4,
      display: 'block',
      paddingBottom: 20,
      fontFamily: 'dosis',
      color: white,
    },
    HeaderTitle2Rows: {
      fontWeight: 600,
      fontSize: 72,
      display: 'block',
      lineHeight: 1.5,
      marginBottom: 20,
      height: 120,
      overflow: 'hidden',
      fontFamily: 'dosis',
    },
    Paragraph: {
      fontWeight: 500,
      fontSize: 18,
      display: 'block',
      paddingBottom: 20,
      lineHeight: 1.5,
      fontFamily: 'Poppins',
    },
    CursiveParagraph: {
      fontWeight: 400,
      fontSize: 18,
      display: 'block',
      color: white,
      lineHeight: 2,
      fontStyle: 'italic',
      fontFamily: 'Poppins',
    },
    TextCards: {
      fontWeight: 500,
      fontSize: 20,
      display: 'block',
      paddingBottom: 20,
      lineHeight: 1.5,
      fontFamily: 'Poppins',
    },
    GreenTitle: {
      display: 'block',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 1.5,
      color: secondary_green,
      paddingBottom: 20,
      fontFamily: 'Dosis',
    },
    BlockTitle: {
      fontFamily: 'Poppins',
      display: 'block',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.5,
      color: black,
      paddingBottom: 20,
    },
    NewsCardTitle: {
      fontFamily: 'Poppins',
      display: 'block',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.5,
      color: black,
    },
    NewsCardTitleElipsis: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.5,
      color: black,

      display: '-webkit-box',
      // webkitLineClamp: '2',
      // webkitBoxOrient: 'vertical',
      // '-webkit-line-clamp': '2',
      // '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    NewsCardCategory: {
      fontFamily: 'Poppins',
      display: 'inline-block',
      verticalAlign: 'middle',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: primary_green,
      textAlign: 'right',
      textTransform: 'uppercase',
    },
    NewsCardCategory2: {
      fontFamily: 'Poppins',
      display: 'inline-block',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: red,
      textAlign: 'right',
      textTransform: 'uppercase',
      marginRight: 5,
    },
    NewsCardDate: {
      fontFamily: 'Poppins',
      display: 'inline-block',
      verticalAlign: 'middle',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: red,
      textAlign: 'right',
    },
    NewsCardDateWithDot: {
      fontFamily: 'Poppins',
      display: 'inline-block',
      verticalAlign: 'middle',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: secondary_green,
      // textAlign: 'right',
    },
    EventBlockTitle: {
      fontFamily: 'Poppins',
      display: 'block',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 1.5,
      color: black,
    },
    EventBlockSubtitle: {
      fontFamily: 'Poppins',
      display: 'block',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
      color: red,
    },
    BlockDateNumber: {
      fontFamily: 'Dosis',
      display: 'inline-block',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.5,
      color: black,
    },
    BlockDateMonth: {
      fontFamily: 'Dosis',
      display: 'inline-block',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.5,
      color: black,
      textTransform: 'uppercase',
    },
    CTAText: {
      fontFamily: 'Dosis',
      display: 'block',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.5,
      color: black,
      textTransform: 'uppercase',
      alignItems: 'center',
    },
    CTAInlineText: {
      fontFamily: 'Dosis',
      display: 'block',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.5,
      color: quarternary_black,
    },
    SearchResultCardTitle: {
      fontFamily: 'Dosis',
      display: 'block',
      fontWeight: 700,
      whiteSpace: 'nowrap',
      fontSize: 32,
      lineHeight: 1.5,
      color: secondary_black,
      textTransform: 'uppercase',
      alignItems: 'center',
    },
    TagText: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.8,
      color: secondary_black,
      alignItems: 'center',
    },
    Breadcrumb: {
      fontFamily: 'Dosis',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.25,
      color: quarternary_black,
      alignItems: 'center',
      textTransform: 'uppercase',
    },
    FacilityText: {
      fontFamily: 'Dosis',
      fontSize: 18,
      textTransform: 'uppercase',
      fontWeight: 700,
      lineHeight: 1.25,
      color: dark_grey,
    },
    FAQTitle: {
      color: fifth_black,
      fontFamily: 'Dosis',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.25,
      display: 'block',
    },
    FAQText: {
      color: secondary_black,
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 2,
      display: 'block',
    },
    // Header
    HeaderColumnTitle: {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.25,
      textTransform: 'uppercase',
      color: black,
    },
    // Footer
    FooterColumnTitle: {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.25,
      textTransform: 'uppercase',
      color: globalTheme?.palette?.footerText?.main,
    },
    FooterColumnItem: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: '#1D1D1B',
    },
    FooterColumnItemThick: {
      fontFamily: 'Dosis',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.25,
      color: globalTheme?.palette?.footerText?.main,
    },
    FooterInfo: {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.25,
      color: globalTheme?.palette?.footerText?.main,
      display: 'block',
    },
    FooterText: {
      fontFamily: 'Dosis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      color: globalTheme?.palette?.footerText?.main,
      display: 'block',
    },
    FooterCopyright: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.5,
      color: globalTheme?.palette?.footerText?.main,
      display: 'block',
    },
    ListTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 1.25,
      display: 'block',
      color: fifth_black,
    },
    ReadMoreWhite: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.5,
      display: 'inline-block',
      verticalAlign: 'middle',
      color: white,
    },
    MoreTitle: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.25,
      display: 'block',
      paddingBottom: 32,
      fontFamily: 'dosis',
      color: black,
    },
    LocationsTitle: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.5,
      display: 'block',
      paddingBottom: 35,
      fontFamily: 'dosis',
      color: fifth_black,
    },
    SubfilterTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.25,
      paddingBottom: 16,
    },
    InfoListTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.5,
      color: fifth_black,
    },
    FourOhFourTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 128,
      lineHeight: 1.26,
      color: white,
    },
    SearchSiteTitle: {
      fontFamily: 'Dosis',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 1.5,
      color: fifth_black,
    },
    SearchSiteDate: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: fifth_black,
    },
    SearchSiteCategory: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.5,
      color: white,
    },
    DateDay: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 48,
      lineHeight: 1.25,
      color: white,
      textAlign: 'center',
    },
    DateMonth: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.25,
      color: white,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    GreenTag: {
      display: 'inline-block',
      verticalAlign: 'middle',
      textTransform: 'uppercase',
      color: secondary_green,
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.5,
    },
    SchemaResultTitle: {
      color: quarternary_black,
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 30,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    OpeningtimeTimeslot: {
      color: white,
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.5,
    },
    // Subsite
    SubsiteHeaderTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.25,
      color: globalTheme?.palette?.footerText.main,
      textTransform: 'uppercase',
    },
    SubsiteTitle: {
      fontFamily: 'Dosis',
      fontWeight: 700,
      fontSize: 48,
      lineHeight: 1.4,
      textTransform: 'uppercase',
      color: subsite_title_blue,
    },
    WeekSelector: {
      fontFamily: 'Dosis',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 1.25,
      color: quarternary_black,
      textTransform: 'uppercase',
    },
    ClubDetails: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 2,
      color: quarternary_black,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: ({}) => ({
          color: quarternary_black
        }),
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            display: 'inline-flex',
            padding: '15px 40px',
            color: white,
            borderRadius: 65,
            backgroundColor: secondary_green,
            '&:hover': {
              backgroundColor: tertiary_green,
              textDecoration: 'none !important',
            },
            '&:focus': {
              backgroundColor: tertiary_green,
              textDecoration: 'none !important',
              outlineColor: '#000 !important',
            },
            '&:active': {
              backgroundColor: tertiary_green,
            },
            svg: {
              marginLeft: 24,
            },
            '@media screen and (max-width: 340px)': {
              maxWidth: '100%',
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            display: 'inline-flex',
            padding: '15px 40px',
            color: white,
            borderRadius: 45,
            backgroundColor: white,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
            '&:focus': {
              textDecoration: 'underline',
            },
            svg: {
              marginLeft: 24,
              path: {
                fill: text_blue,
              },
            },
            '.MuiTypography-ButtonText': {
              color: text_blue,
            },
          },
        },
        {
          props: { variant: 'arrow' },
          style: {
            marginLeft: '20px',
            display: 'inline-block',
            padding: '15px 40px',
            color: white,
            backgroundColor: primary_green,
            height: '76px',
            width: '90px',
            '&:hover': {
              backgroundColor: tertiary_green,
            },
            '&:focus': {
              backgroundColor: tertiary_green,
              outlineColor: '#000 !important',
            },
            '&:active': {
              backgroundColor: tertiary_green,
            },

            '.svgContainer': {
              paddingTop: '10px',
              marginLeft: '-10px',
            },
          },
        },
        {
          props: { variant: 'border' },
          style: {
            display: 'block',
            padding: '42px 58px',
            backgroundColor: 'transparent',
            border: `1px solid ${grey}`,
            position: 'relative',
            svg: {
              position: 'absolute',
              right: 58,
              top: 0,
              float: 'right',
              height: '100%',
              width: 24,
              path: {
                stroke: null,
                fill: black,
              },
            },
          },
        },
        {
          props: { variant: 'return' },
          style: {
            display: 'inline-flex',
            padding: '15px 40px',
            color: white,
            borderRadius: 65,
            backgroundColor: secondary_green,
            '&:hover': {
              backgroundColor: tertiary_green,
              textDecoration: 'none !important',
            },
            '&:focus': {
              backgroundColor: tertiary_green,
              textDecoration: 'none !important',
              outlineColor: '#000 !important',
            },
            '&:active': {
              backgroundColor: tertiary_green,
            },
            svg: {
              marginRight: 12,
              width: 10,
            },
          },
        },
        {
          props: { variant: 'base' },
          style: {
            textDecoration: 'none',
            color: 'inherit',
            '&.Mui-focusVisible': {
              '> span': {
                outline: '#007aff auto 1px',
                img: {
                  backgroundColor: '#007aff !important',
                },
              },
              '&.focusibleOutline': {
                outline: '#007aff auto 1px',
              },
            },
          },
        },
        {
          props: { variant: 'baseArrow' },
          style: {
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'row',
            gap: 43,
            alignItems: 'center',
          },
        },
        {
          props: { variant: 'innerSkewed' },
          style: {
            textDecoration: 'none',
            color: 'inherit',
            '&.Mui-focusVisible': {
              outline: 0,
              '> div > .skewWrapper': {
                '&:before ': {
                  outline: `${secondary_green} auto 1px`,
                },
              },
              '.skewContainerContainer > div > .skewWrapper': {
                '&:before ': {
                  outline: `${secondary_green} auto 1px`,
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: () => ({
          '@media screen and (max-width: 340px)': {
            maxWidth: '100%',
            'svg.arrowRight': {
              display: 'none',
            },
          },
        }),
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            padding: '15px 40px',
            color: globalTheme.palette.primaryCustom.contrastText,
            borderRadius: 65,
            backgroundColor: globalTheme.palette.primaryCustom.main,
            '&:hover': {
              backgroundColor: globalTheme.palette.primaryCustom.dark,
            },
            '&:focus': {
              backgroundColor: globalTheme.palette.primaryCustom.dark,
            },
            '&:active': {
              backgroundColor: globalTheme.palette.primaryCustom.dark,
            },
            svg: {
              marginLeft: 24,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            padding: '15px 40px',
            color: button_text_black,
            borderRadius: 65,

            svg: {
              marginLeft: 42,
              transform: 'translateX(0)',
              transition: 'all 200ms',
              path: {
                stroke: button_text_black,
              },
            },
            '&:hover': {
              color: button_text_black_hover,
              backgroundColor: 'transparent',
              svg: {
                transform: 'translateX(10px)',
                // marginLeft: 52,
                path: {
                  stroke: button_text_black_hover,
                },
              },
            },
            '&:focus': {
              color: button_text_black_hover,
              backgroundColor: 'transparent',
              svg: {
                marginLeft: 52,
                path: {
                  stroke: button_text_black_hover,
                },
              },
            },
            '&:active': {
              color: button_text_black_hover,
            },
            '&.Mui-focusVisible': {
              outline: `${secondary_green} auto 1px`,
            },
          },
        },
        {
          props: { variant: 'blank' },
          style: {
            padding: '11px 17px',
            color: black,
            backgroundColor: white,
            border: '1px solid #E3E3E3',

            '&:hover': {
              color: button_text_black_hover,
              backgroundColor: second_button_grey,
            },
            '&:focus': {
              color: button_text_black_hover,
              backgroundColor: second_button_grey,
            },
            '&:active': {
              color: button_text_black_hover,
              backgroundColor: second_button_grey,
            },
          },
        },
        {
          props: { variant: 'empty' },
          style: {
            color: '#202020',
            fontFamily: 'Dosis',
            fontStyle: 'normal',
            fontSize: 24,
            lineHeight: 1.25,
            textTransform: 'none',

            '&.Mui-focusVisible': {
              outline: `${secondary_green} auto 1px`,
            },
          },
        },
        {
          props: { variant: 'plus' },
          style: {
            padding: 0,
            color: quarternary_black,
            backgroundColor: 'transparent',
            textTransform: 'none',

            '&:hover': {
              color: button_text_black_hover,
              // backgroundColor: second_button_grey,
            },
            '&:focus': {
              color: button_text_black_hover,
              // backgroundColor: second_button_grey,
            },
            '&:active': {
              color: button_text_black_hover,
              // backgroundColor: second_button_grey,
            },
            '&.Mui-focusVisible': {
              outline: `${secondary_green} auto 1px`,
            },
          },
        },
        {
          props: { variant: 'upload' },
          style: {
            padding: '16px 40px',
            color: white,
            borderRadius: 65,
            backgroundColor: secondary_green,
            '&:hover': {
              backgroundColor: tertiary_green,
            },
            '&:focus': {
              backgroundColor: tertiary_green,
              outlineColor: '#000 !important',
            },
            '&:active': {
              backgroundColor: tertiary_green,
            },
            '&.Mui-focusVisible': {
              outline: `${secondary_green} auto 1px`,
              backgroundColor: white,
              color: secondary_green,
            },
            svg: {
              marginRight: 13,
            },
            span: {
              fontSize: 18,
              lineHeight: '22.75px',
            },
          },
        },
        {
          props: { variant: 'search' },
          style: {
            padding: '22px 48px',
            color: white,
            borderRadius: '0 26px 26px 0',
            backgroundColor: secondary_green,
            height: 52,
            ' > span': {
              margin: 0,
              fontSize: 18,
            },
            '&:hover': {
              backgroundColor: tertiary_green,
            },
            '&:focus': {
              backgroundColor: tertiary_green,
              outlineColor: '#000 !important',
            },
            '&:active': {
              backgroundColor: tertiary_green,
            },
            svg: {
              marginLeft: 24,
              color: white,
            },
          },
        },
        {
          props: { variant: 'iconButton' },
          style: {
            padding: '11px 17px',
            color: fifth_black,
            borderRadius: '59px',
            backgroundColor: button_grey,
            ' > span': {
              margin: 0,
              fontSize: 18,
            },

            svg: {
              marginRight: 10,
            },
            '&:hover': {
              backgroundColor: second_button_grey,
            },
            '&:focus': {
              backgroundColor: second_button_grey,
            },
          },
        },
        {
          props: { variant: 'more' },
          style: {
            margin: 0,
            padding: 0,
          },
        },
        {
          props: { variant: 'moreFilters' },
          style: {
            padding: 0,
            marginTop: 24,
            paddingTop: 0,
            '&.Mui-focusVisible': {
              outline: `${secondary_green} auto 1px`,
            },
          },
        },
      ],
      styleOverrides: {
        root: () => ({
          '@media screen and (max-width: 340px)': {
            maxWidth: '100%',
            'svg.arrowRight': {
              display: 'none',
            },
          },
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-error': {
            border: `1px solid ${red}`,
          },
          ...(ownerState.issearch
            ? {
                display: 'inline-block',
                width: '100%',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22,75px',
                color: fifth_black,
                ':before': {
                  display: 'none',
                },
                ':after': {
                  display: 'none',
                },
              }
            : {
                width: '100%',
                border: `1px solid ${grey}`,
                borderRadius: 2,
                background: lightgrey,
                padding: '8px 22px',
                fontSize: '18px',
                ':before': {
                  display: 'none',
                },
                ':after': {
                  display: 'none',
                },
                textarea: {
                  height: '255px !important',
                },
              }),
        }),
        input: () => ({
          padding: 0,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          input: {
            padding: 0,
          },
          '&.Mui-error': {
            border: `1px solid ${red}`,
          },
          '&.Mui-focused': {
            outline: `${secondary_green} auto 0px`,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: () => ({
          width: '100%',
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: '100%',
          border: `1px solid ${grey}`,
          borderRadius: 2,
          background: lightgrey,
          padding: '8px 22px',
          fontSize: '18px',
          ':before': {
            display: 'none',
          },
          ':after': {
            display: 'none',
          },
          textarea: {
            height: '255px !important',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: (ownerState) => ({
          ...(ownerState.issearch && {
            background: white,
            borderRadius: 26,
            height: 52,
            maxWidth: 183,
            borderColor: tag_border_grey,
            textTransform: 'uppercase',
            fontSize: 18,
            fontWeight: 600,
            fontFamily: 'Dosis',
            lineHeight: '22.75px',
            color: fifth_black,
            '.MuiSelect-select': {
              marginBottom: '-2px',
            },
          }),
        }),
        select: (ownerState) => ({
          padding: 0,
          ...(ownerState.issearch && {
            marginBottom: '-2px',
          }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: 2,
          color: quarternary_black,
          display: 'block',
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: () => ({
          svg: {
            height: '18px',
            color: secondary_green,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          height: '18px',
          width: '18px',
          paddingRight: '32px',
          input: {
            width: '18px',
          },
          '&.Mui-focusVisible': {
            '> svg': {
              fill: secondary_green,
              stroke: secondary_green,
            },
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ boldlable }) => ({
          ...(boldlable && {
            'white-space': 'normal !important',
            fontWeight: '800 !important',
            lineHeight: '1.5 !important',
            marginTop: 2,
          }),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          fontSize: '18px',
          marginLeft: 0,
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          "[data-type='datepicker']": {
            '.MuiInputAdornment-root': {
              display: 'none',
            },
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: () => ({
          backgroundColor: 'white',
          border: '1px solid #8C8C8C',
        }),
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          color: tertiary_black,
          '&.Mui-selected': {
            '&:not(.Mui-disabled)': {
              backgroundColor: 'rgba(59, 170, 52, 0.4)',
              fontWeight: 700,
            },
          },
        }),
      },
    },
    // List items
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: tertiary_black,
          padding: '2px 16px',
          paddingLeft: ownerState.noleftpadding ? 0 : 16,
          ...ownerState.cursive,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 0,
          margin: 0,
          svg: {
            marginRight: ownerState.isinfo ? 0 : 16,
            marginLeft: 0,
            width: 21,
          },
          '&.Mui-selected': {
            backgroundColor: 'unset',
            span: {
              fontWeight: 700,
            },
          },
          '&.Mui-focusVisible': {
            outline: `${secondary_green} auto 1px`,
            backgroundColor: 'transparent !important',
          },
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          ...(ownerState.isinfo && {
            marginBottom: 6,
            '> *': {
              display: 'inline-block',
              verticalAlign: 'middle',
            },
            svg: {
              height: 21,
            },
          }),
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: ownerState.nomarker ? 'block' : 'list-item',
          ...(ownerState.isCursive && {
            fontStyle: 'italic',
            display: 'block',
            fontWeight: '400 !important',
            fontFamily: 'Poppins',
            lineHeight: 2,
            marginLeft: 20,
          }),
        }),
        primary: ({ ownerState }) => ({
          ...(ownerState.isinfo
            ? {
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: 18,
                lineHeight: '22px',
                color: button_text_black,
              }
            : {
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: ownerState.isfilter ? 18 : 16,
                lineHeight: 1.5,
                textTransform: ownerState.texttransform,
              }),
        }),
      },
    },
    // pagination
    MuiPaginationItem: {
      styleOverrides: {
        root: () => ({
          backgroundColor: grey_background,
          color: fifth_black,
          borderRadius: 0,
          padding: '10px 18px',
          margin: '0 8px',
          width: 40,
          height: 40,
          fontFamily: 'Dosis',
          fontStyle: 'normal',
          fontSize: 16,
          lineHeight: 1.25,
          '&.Mui-focusVisible': {
            outline: `${secondary_green} auto 1px`,
            color: '#000',
          },
          '&.Mui-selected': {
            backgroundColor: secondary_green,
            color: white,
          },
        }),
      },
    },
    // DatePicker
    MuiCalendarPicker: {
      styleOverrides: {
        root: () => ({
          width: 450,
          '.grid': {
            '.row': {
              gap: 8,
            },
          },
          '[role=presentation]': {
            '[aria-live=polite]': {
              fontFamily: 'Dosis',
              fontWeight: 700,
              fontSize: 30,
              lineHeight: '44px',
              color: quarternary_black,
              textTransform: 'uppercase',
              marginBottom: 8,
            },
          },
        }),
        viewTransitionContainer: () => ({
          '.MuiTypography-caption': {
            fontFamily: 'Dosis',
            fontWeight: 700,
            fontSize: 20,
            lineHeight: 1.5,
            color: quarternary_black,
            margin: '0 6px',
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: () => ({
          '&[role=datepicker]': {
            width: 450,
            '> div': {
              '> div': {
                width: 450,
              },
            },
          },
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: () => ({
          fontFamily: 'Dosis',
          fontWeight: 700,
          fontSize: 20,
          lineHeight: 1.5,
          borderRadius: 0,
          '&:not(.Mui-selected)': {
            borderColor: red,
          },
          '&.Mui-selected': {
            backgroundColor: red,
            '&:hover': {
              backgroundColor: red,
              'will-change': 'unset',
            },
            '&:not(:hover)': {
              backgroundColor: red,
              'will-change': 'unset',
            },
          },
        }),
      },
    },
    // Table
    MuiTableHead: {
      variants: [
        {
          props: { variant: 'search' },
          style: {
            backgroundColor: lightgrey_background,
            color: grey,
          },
        },
      ],
    },
  },
  globalTheme,
})

export default theme
